import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { dateInterface, selectedDate, selectedMonth } from 'src/app/core/interfaces/interfaces';
import { SelectBoxService } from 'src/app/core/services/selectBox/select-box.service';
import { selectConfig } from 'src/app/core/store/config/selector/config.selectors';
import { Dates } from 'src/app/core/store/helpers/actions/helpers.actions';
import {
  selectDatesStoreHistory,
  selectDatesTransactions,
} from 'src/app/core/store/helpers/selector/helpers.selectors';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { DatepickersButtonComponent } from '../../buttons/datepickers-button/datepickers-button.component';
import { NgIf, NgFor } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-select-box-interval-date',
  templateUrl: './select-box-interval-date.component.html',
  styleUrls: ['./select-box-interval-date.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, NgFor, DatepickersButtonComponent],
})
export class SelectBoxIntervalDateComponent implements OnInit, OnDestroy {
  @Input()
  modalId: string = '';
  config$: Observable<any> = this.store.select(selectConfig);
  datesTransactions$: Observable<any> = this.store.select(selectDatesTransactions);
  datesStoreHistory$: Observable<any> = this.store.select(selectDatesStoreHistory);
  unsubus$: Subject<boolean> = new Subject();

  registeredTime: dateInterface = {
    day: 0,
    month: 0,
    year: 0,
  };
  daysOfWeek: Array<string> = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  date!: Date;
  currentParsedDate: dateInterface = {
    day: 0,
    month: 0,
    year: 0,
  };

  emptyDay = {
    day: 0,
    variant: '',
    stopSelect: false,
  };
  emptyMonth = {
    year: 0,
    month: '',
    monthDate: '',
    days: [],
  };
  emptyDateObject = {
    month: this.emptyMonth,
    day: this.emptyDay,
    selected: false,
  };
  activeMonths: Array<selectedMonth> = [];
  selectedMonthLeft: number = 0;
  selectedMonthRight: number = 0;
  selectedInitialDate: selectedDate = this.emptyDateObject;
  selectedFinalDate: selectedDate = this.emptyDateObject;
  initialDate: string = '';
  finalDate: string = '';
  selectedMonthLeftPage!: number;
  selectedMonthRightPage!: number;
  closed: boolean = false;
  isInactive: boolean = false;
  constructor(
    private store: Store,
    private selectBoxService: SelectBoxService
  ) {}
  ngOnInit(): void {
    // this.config$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
    //   if (res.isLoaded) {
    //     if (res.config.loggedIn == '1') {
    //       const d = res.config.user.reg_time.split(' ')[0].split('-');
    //       this.registeredTime = {
    //         day: d[2],
    //         month: d[1],
    //         year: d[0],
    //       };
    //     }
    //     this.createMonths();
    //   }
    // });

    const today = new Date();

    this.registeredTime = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear() - 1,
    };
    this.createMonths();
    if (this.modalId == 'dateSelectionTransactions') {
      this.datesTransactions$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
        if (res.initialDate !== undefined && res.finalDate !== undefined) {
          if (!this.closed) {
            this.selectedMonthLeftPage = res.selectedMonthLeftPage;
            this.selectedMonthRightPage = res.selectedMonthRightPage;
            if (res.initialDate.month.year !== 0) {
              this.selectInitialDate(res.initialDate.day, res.initialDate.month);
            }
            if (res.finalDate.month.year !== 0) {
              this.selectFinalDate(res.finalDate.day, res.finalDate.month);
            }
          }
        }
      });
    }
    if (this.modalId == 'dateSelectionStoreHistory') {
      this.datesStoreHistory$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
        if (res.initialDate !== undefined && res.finalDate !== undefined) {
          if (!this.closed) {
            this.selectedMonthLeftPage = res.selectedMonthLeftPage;
            this.selectedMonthRightPage = res.selectedMonthRightPage;
            this.selectInitialDate(res.initialDate.day, res.initialDate.month);
            this.selectFinalDate(res.finalDate.day, res.finalDate.month);
          }
        }
      });
    }
  }
  getValues(item: any, value: boolean) {
    const valuesToCheck =
      item.stopSelect ||
      (this.isInactive && item.variant === 'default') ||
      (item.variant === 'default' &&
        this.selectedFinalDate.month.month !== this.activeMonths[this.selectedMonthRightPage].month &&
        this.activeMonths[this.selectedMonthRightPage].monthDate < this.selectedInitialDate.month.monthDate &&
        this.activeMonths[this.selectedMonthRightPage].year === this.selectedInitialDate.month.year) ||
      (item.day < this.selectedInitialDate.day.day &&
        this.selectedFinalDate.month.month === this.selectedInitialDate.month.month &&
        this.activeMonths[this.selectedMonthRightPage].month === this.selectedFinalDate.month.month &&
        item.variant === 'default') ||
      (item.variant === 'default' &&
        this.selectedFinalDate.month.month !== this.activeMonths[this.selectedMonthRightPage].month &&
        !this.selectedFinalDate.selected) ||
      (item.variant === 'default' &&
        this.selectedFinalDate.selected &&
        this.selectedInitialDate.selected &&
        this.selectedFinalDate.month.year > this.activeMonths[this.selectedMonthRightPage].year &&
        this.activeMonths[this.selectedMonthRightPage].monthDate >= this.selectedInitialDate.month.monthDate);
    if (!value) {
      if (valuesToCheck) {
        return true;
      } else {
        return false;
      }
    } else {
      if (valuesToCheck) {
        return '';
      } else {
        return this.selectFinalDate(item, this.activeMonths[this.selectedMonthRightPage]);
      }
    }
  }
  createMonths() {
    this.activeMonths = [];
    this.date = new Date();
    this.currentParsedDate = {
      day: this.date.getDate(),
      month: this.date.getMonth() + 1,
      year: this.date.getFullYear(),
    };

    const activeMonths =
      (this.currentParsedDate.year - this.registeredTime.year) * 12 +
      this.currentParsedDate.month -
      this.registeredTime.month +
      1;

    this.activeMonths = this.selectBoxService.getActiveMonths(
      this.currentParsedDate,
      this.registeredTime,
      this.activeMonths
    );
    this.activeMonths = this.activeMonths.splice(this.registeredTime.month - 1, activeMonths);
    if (activeMonths <= 1) {
      this.selectedMonthLeftPage = this.activeMonths.length - 1;
    } else {
      this.selectedMonthLeftPage = this.activeMonths.length - 2;
    }
    this.selectedMonthRightPage = this.activeMonths.length - 1;
    this.selectedInitialDate.selected = false;
    this.selectedFinalDate.selected = false;
  }

  selectInitialDate(item: any, month: any, monthPage?: any) {
    if (monthPage !== undefined) {
      this.selectedMonthLeftPage = monthPage;
    }
    if (this.selectedFinalDate.day !== this.emptyDay) {
      if (
        this.selectedMonthLeftPage > this.selectedMonthRightPage ||
        (item.day > this.selectedFinalDate.day.day && this.selectedMonthLeftPage == this.selectedMonthRightPage)
      ) {
        return;
      }
    }
    this.selectedMonthLeft = this.selectedMonthLeftPage;
    this.selectedInitialDate = {
      month: month,
      day: item,
      selected: true,
    };
    this.activeMonths = this.selectBoxService.checkForActiveDaysInitial({
      selectedMonthLeftPage: this.selectedMonthLeftPage,
      selectedInitialDate: this.selectedInitialDate,
      selectedMonthRightPage: this.selectedMonthRightPage,
      selectedFinalDate: this.selectedFinalDate,
      activeMonths: this.activeMonths,
      item: item,
    });
    this.selectedInitialDate.selected = true;
    this.getStartingDate();
    this.activeMonths = this.selectBoxService.clearLeftDate(
      this.activeMonths,
      this.selectedMonthLeftPage,
      this.selectedInitialDate
    );
    if (this.selectedFinalDate.day !== this.emptyDay) {
      this.activeMonths = this.selectBoxService.clearRightDate(
        this.activeMonths,
        this.selectedMonthRightPage,
        this.selectedFinalDate
      );
      this.isInactive = false;
    } else {
      this.isInactive = true;
    }
  }
  selectFinalDate(item: any, month: any) {
    if (this.selectedFinalDate.day !== this.emptyDay || this.selectedMonthRightPage < this.selectedMonthLeftPage) {
      if (this.selectedInitialDate.day !== this.emptyDay) {
        if (
          (this.selectedMonthLeftPage == this.selectedMonthRightPage && item.day < this.selectedInitialDate.day.day) ||
          this.selectedMonthRightPage < this.selectedMonthLeftPage
        ) {
          return;
        }
      }
    }
    this.selectedMonthRight = this.selectedMonthRightPage;

    this.selectedFinalDate = {
      month: month,
      day: item,
      selected: true,
    };
    this.activeMonths = this.selectBoxService.checkForActiveDaysFinal({
      selectedMonthLeftPage: this.selectedMonthLeftPage,
      selectedInitialDate: this.selectedInitialDate,
      selectedMonthRightPage: this.selectedMonthRightPage,
      selectedFinalDate: this.selectedFinalDate,
      activeMonths: this.activeMonths,
      item: item,
      selectedMonthLeft: this.selectedMonthLeft,
      selectedMonthRight: this.selectedMonthRight,
    });
    this.selectedFinalDate.selected = true;
    this.getFinalDate();
    this.activeMonths = this.selectBoxService.clearRightDate(
      this.activeMonths,
      this.selectedMonthRightPage,
      this.selectedFinalDate
    );
    this.isInactive = false;
  }

  prevPageLeft() {
    this.selectedMonthLeftPage -= 1;
  }
  nextPageLeft() {
    this.selectedMonthLeftPage += 1;
  }
  prevPageRight() {
    this.selectedMonthRightPage -= 1;
  }
  nextPageRight() {
    this.selectedMonthRightPage += 1;
  }
  getStartingDate() {
    if (
      !this.selectedInitialDate?.month?.month ||
      this.selectedInitialDate.day.day == 0 ||
      this.selectedInitialDate.month.year == 0
    ) {
      this.initialDate = '';
      return;
    }
    this.initialDate = `${this.selectedInitialDate.month.month}  ${this.selectedInitialDate.day.day}, ${this.selectedInitialDate.month.year}`;
  }
  getFinalDate() {
    if (
      !this.selectedFinalDate?.month?.month ||
      this.selectedFinalDate.day.day == 0 ||
      this.selectedFinalDate.month.year == 0
    ) {
      this.finalDate = '';
      return;
    }
    this.finalDate = `${this.selectedFinalDate.month.month}  ${this.selectedFinalDate.day.day}, ${this.selectedFinalDate.month.year}`;
  }
  clearDateAll() {
    this.selectedFinalDate = this.emptyDateObject;
    this.selectedInitialDate = this.emptyDateObject;
    this.initialDate = '';
    this.finalDate = '';
    this.createMonths();
  }
  clearDateRight() {
    const selectedMonth = this.selectedMonthLeft;
    this.selectedFinalDate = this.emptyDateObject;
    this.finalDate = '';
    this.createMonths();
    this.selectInitialDate(this.selectedInitialDate.day, this.selectedInitialDate.month, selectedMonth);
  }
  closeModal() {
    this.closed = true;
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  sendDates() {
    this.closeModal();
    if (this.modalId == 'dateSelectionTransactions') {
      this.store.dispatch(
        Dates['[dates]SetDatesTransactions']({
          initialDate: this.selectedInitialDate,
          finalDate: this.selectedFinalDate,
          selectedMonthLeftPage: this.selectedMonthLeftPage,
          selectedMonthRightPage: this.selectedMonthRightPage,
        })
      );
    } else if (this.modalId == 'dateSelectionStoreHistory') {
      this.store.dispatch(
        Dates['[dates]SetDatesStoreHistory']({
          initialDate: this.selectedInitialDate,
          finalDate: this.selectedFinalDate,
          selectedMonthLeftPage: this.selectedMonthLeftPage,
          selectedMonthRightPage: this.selectedMonthRightPage,
        })
      );
    }
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
